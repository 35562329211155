<template>
  <v-card
    v-bind="$attrs"
  >
    <v-btn
      text
      block
      tile
      large
      @click="close"
    >
      <div
        class="d-flex justify-start align-center full-width"
      >
        <v-icon
          left
        >
          keyboard_arrow_left
        </v-icon>

        <span
          v-translate
          translate-context="MForgotPassword component back button"
        >
          Go back
        </span>
      </div>
    </v-btn>

    <v-divider />

    <v-card-title
      v-translate
      translate-context="MForgotPassword component title"
    >
      Lost your password?
    </v-card-title>

    <v-card-subtitle
      v-translate
      translate-context="MForgotPassword component subtitle"
    >
      From here you can request a single-use link for setting a new password.
    </v-card-subtitle>

    <v-card-text
      class="pb-2"
    >
      <div>
        <v-btn-toggle
          v-model="currentTab"
          mandatory
          class="full-width"
        >
          <v-btn
            v-if="hasActiveSubscription"
            small
            class="flex-grow-1"
            value="webpush"
          >
            <v-icon
              small
              left
            >
              notifications
            </v-icon>
            Push
          </v-btn>

          <v-btn
            small
            class="flex-grow-1"
            value="email"
          >
            <v-icon
              small
              left
            >
              email
            </v-icon>

            <span
              v-translate
              translate-context="MForgotPassword component choose email button"
            >
              Email
            </span>
          </v-btn>

          <v-btn
            small
            class="flex-grow-1"
            value="phone"
          >
            <v-icon
              small
              left
            >
              phone
            </v-icon>

            <span
              v-translate
              translate-context="MForgotPassword component choose SMS button"
            >
              SMS
            </span>
          </v-btn>
        </v-btn-toggle>
      </div>
    </v-card-text>

    <div
      v-show="currentTab === 'webpush'"
    >
      <v-card-text
        v-translate
        translate-context="MForgotPassword component content"
        class="body-2"
        style="height: 100px;"
      >
        Get link as a push notification.
        By clicking on the notification, you will be redirected to
        a website where you can set a new password.
      </v-card-text>

      <v-card-actions
        class="px-4"
      >
        <v-btn
          :disabled="$wait.is('processing')"
          :loading="$wait.is('processing')"
          color="primary"
          block
          large
          @click="onWebPushClick"
        >
          <span
            v-translate
            translate-context="MForgotPassword component choose push notification button"
          >
            Request push notification
          </span>
        </v-btn>
      </v-card-actions>
    </div>

    <v-form
      v-show="currentTab === 'email'"
      ref="emailForm"
      v-model="emailFormValid"
      @submit.prevent="submitEmail"
    >
      <v-card-text
        style="height: 100px;"
      >
        <v-text-field
          v-model="tmpEmail"
          label="Sähköposti"
          type="email"
          outlined
          validate-on-blur
          autofocus
          hide-details
          :rules="[validators.required, validators.email]"
        />
      </v-card-text>

      <v-card-actions
        class="px-4"
      >
        <v-btn
          :disabled="$wait.is('processing')"
          :loading="$wait.is('processing')"
          color="primary"
          block
          large
          type="submit"
        >
          <span
            v-translate
            translate-context="MForgotPassword component send button"
          >
            Send
          </span>
        </v-btn>
      </v-card-actions>
    </v-form>

    <v-form
      v-show="currentTab === 'phone'"
      ref="phoneForm"
      v-model="phoneFormValid"
      @submit.prevent="submitPhone"
    >
      <v-card-text
        style="height: 100px;"
      >
        <div
          class="d-flex"
        >
          <v-select
            v-model="selectedAreaCode"
            :items="areaCodes"
            :label="countryCodePlaceholder"
            hide-details
            :menu-props="{ offsetY: true }"
            outlined
            style="flex: 1;"
          />

          <v-text-field
            v-model="tmpPhone"
            v-mask="'## ### ####'"
            :label="phoneNumberPlaceholder"
            type="tel"
            outlined
            validate-on-blur
            autofocus
            hide-details
            style="flex: 3;"
            :rules="[validators.required]"
          />
        </div>

        <div
          v-translate="{ phoneNumber }"
          translate-context="MForgotPassword component content"
          class="caption-xs mt-1 ml-2"
        >
          Will be sent to %{ phoneNumber }
        </div>
      </v-card-text>

      <v-card-actions
        class="px-4"
      >
        <v-btn
          :disabled="$wait.is('processing')"
          :loading="$wait.is('processing')"
          color="primary"
          block
          large
          type="submit"
        >
          <span
            v-translate
            translate-context="MForgotPassword component send button"
          >
            Send
          </span>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mask } from 'vue-the-mask';
  import { mapWaitingActions } from 'vue-wait';
  import { mapActions, mapGetters } from 'vuex';
  import getValidators from '@/mixins/validators';

  export default {
    directives: {
      mask,
    },

    props: {
      onClose: {
        type: Function,
        required: true,
      },

      email: {
        type: String,
        required: true,
      },

      show: {
        type: Boolean,
        required: true,
      },
    },

    data: () => ({
      tmpEmail: '',
      tmpPhone: '',
      emailFormValid: false,
      phoneFormValid: false,
      currentTab: null,
      selectedAreaCode: '+358',
      areaCodes: [
        '+358',
        '+372',
      ],
      ...getValidators('required', 'email'),
    }),

    computed: {
      ...mapGetters({
        hasActiveSubscription: 'sw/hasActiveSubscription',
        activeSubscription: 'sw/activeSubscription',
      }),

      phoneNumber() {
        return `${this.selectedAreaCode} ${this.tmpPhone}`;
      },

      countryCodePlaceholder() {
        return this.$pgettext('MForgotPassword component select input placeholder', 'Country code');
      },

      phoneNumberPlaceholder() {
        return this.$pgettext('MForgotPassword component text input placeholder', 'Phone number');
      },
    },

    watch: {
      show: {
        immediate: true,
        handler(show) {
          if (show) {
            this.tmpEmail = this.email;
            this.currentTab = this.hasActiveSubscription ? 'webpush' : 'email';
          }
        },
      },
    },

    methods: {
      ...mapActions({
        addNotification: 'snackbar/addNotification',
      }),

      ...mapWaitingActions('currentUser', {
        sendPasswordReset: 'processing',
      }),

      async submitEmail() {
        const valid = await this.$refs.emailForm.validate();

        if (!valid) return;

        await this.submit({
          credential: this.tmpEmail,
          deliveryMethod: 'email',
        });

        this.close();
      },

      async submitPhone() {
        const valid = await this.$refs.phoneForm.validate();

        if (!valid) return;

        await this.submit({
          credential: this.selectedAreaCode + this.tmpPhone,
          deliveryMethod: 'sms',
        });

        this.close();
      },

      async onWebPushClick() {
        const {
          endpoint: credential,
        } = this.activeSubscription;

        await this.submit({
          credential,
          deliveryMethod: 'webpush',
        });

        this.close();
      },

      async submit({ credential, deliveryMethod }) {
        const success = await this.sendPasswordReset({
          credential,
          deliveryMethod,
        });

        if (!success) return;

        const msgs = {
          success: this.$pgettext('MForgotPassword component link sent message', 'Link sent'),
          fail: this.$pgettext('MForgotPassword component link sent message', 'Sending link failed'),
        };

        this.addNotification({
          msg: success ? msgs.success : msgs.fail,
          type: success ? 'success' : 'error',
        });
      },

      close() {
        this.tmpEmail = '';
        this.tmpPhone = '';
        this.emailFormValid = false;
        this.phoneFormValid = false;

        this.onClose();
      },
    },
  };
</script>
