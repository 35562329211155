<template>
  <v-container
    fluid
    class="d-flex flex-column full-height pt-4"
  >
    <div class="bg" />

    <v-row
      style="transform: translateZ(0);"
    >
      <v-col>
        <v-img
          src="/favicons/favicon-96x96.png"
          max-width="48"
          class="mx-auto"
        />

        <div
          class="text-center white--text font-weight-bold display-1"
        >
          mestamaster
        </div>

        <h2
          v-translate
          translate-context="Register page title"
          class="font-weight-bold
                  text-center mt-1 mb-4 primary--text text--lighten-5"
        >
          Create a Mestamaster account
        </h2>
        <v-form
          ref="form"
          v-model="formValid"
          @submit.prevent="submit"
        >
          <v-card
            max-width="500px"
            class="mx-auto elevation-6 py-4"
          >
            <v-card-text
              class="pb-0"
            >
              <v-text-field
                  v-model="user.firstName"
                  autofocus
                  :label="firstNamePlaceholder"
                  outlined
                  validate-on-blur
                  autocomplete="given-name"
                  type="text"
                  :rules="[validators.required]"
                />
              <v-text-field
                v-model="user.lastName"
                :label="lastNamePlaceholder"
                outlined
                validate-on-blur
                autocomplete="family-name"
                type="text"
                :rules="[validators.required]"
              />
              <v-text-field
                v-model="user.email"
                :label="emailPlaceholder"
                outlined
                validate-on-blur
                autocomplete="email"
                type="email"
                :rules="[validators.required, validators.email]"
              />

              <!-- phone -->
              <m-phone-input
                outlined
                class="mb-6"
                :user="user"
                :required="true"
              />

              <v-text-field
                v-model="user.password"
                :label="passwordPlaceholder"
                outlined
                validate-on-blur
                autocomplete="new-password"
                :rules="[validators.required]"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                @click:append="showPassword = !showPassword"
              />

              <v-checkbox
                class="text-small"
                v-model="user.termsChecked"
                :rules="[validators.required]"
                :label="termsAndConditionsText"
                id="terms-and-conditions-acceptance"
              >
              <template v-slot:label>
                <span class="caption">
                  <span v-translate>
                    I agree with Mestamaster's
                  </span>
                  <a @click.stop target="_blank" href="https://www.mestamaster.com/privacy-policy" v-translate>
                    terms and conditions
                  </a> and 
                  <a @click.stop target="_blank" href="https://www.mestamaster.com/privacy-policy" v-translate>
                    privacy policy
                  </a>
                </span>
              </template>
            </v-checkbox>

            </v-card-text>
            <v-card-actions
              class="px-4"
            >
              <div
                class="full-width"
              >
                <v-btn
                  color="primary"
                  block
                  large
                  height="54px"
                  type="submit"
                  :loading="$wait.is('submitting registration')"
                  :disabled="$wait.is('submitting registration')"
                >
                  Register
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-footer app bottom fixed
      class="text-center d-block caption"
    >
      {{ new Date().getFullYear() }} &copy; Mestamaster Oy
    </v-footer>
  </v-container>
</template>

<script>
  import { mapWaitingActions } from 'vue-wait';
  import getValidators from '@/mixins/validators';
  import MForgotPassword from '@/components/MForgotPassword';
  import MPhoneInput from '@/components/forms/MPhoneInput';

  export default {
    components: {
      MForgotPassword,
      MContactDetails: () => import('@/components/MContactDetails'),
      MPhoneInput
    },

    props: {
      redirect: {
        type: String,
        default: null,
      },

      token: {
        type: String,
        default: null,
      },
    },

    data: () => ({
      showPassword: false,
      formValid: false,
      showPasswordLost: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        termsChecked: false,
        phoneCountryCode: '+358',
        nationalPhoneNumber: '',
      },
      ...getValidators('required', 'email'),
    }),

    computed: {
      emailPlaceholder() {
        return this.$pgettext('Text input placeholder', 'Email');
      },

      firstNamePlaceholder() {
        return this.$pgettext('Text input placeholder', 'First name');
      },

      lastNamePlaceholder() {
        return this.$pgettext('Text input placeholder', 'Family name');
      },

      passwordPlaceholder() {
        return this.$pgettext('Text input placeholder', 'Password');
      },

      termsAndConditionsText() {
        return this.$pgettext(
          'Terms and conditions checkbos',
          'I have read and accepted the terms and condition, and our Data processing policy');
      }
    },

    created() {
      if (this.token) this.tryAutoLogin();
    },

    methods: {
      ...mapWaitingActions('currentUser', {
        register: 'submitting registration',
      }),

      async submit() {
        await this.$refs.form.validate();
        if (!this.formValid) return;

        const success = await this.register(
          {
            email: this.user.email,
            password: this.user.password,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            phoneCountryCode: this.user.phoneCountryCode,
            nationalPhoneNumber: this.user.nationalPhoneNumber,
          }
        );

        if (!success) return;
        await this.registerOk();
      },

      async registerOk() {
        const to = this.redirect || { name: 'projects-list' };
        await this.$router.replace(to);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 300px;
    background-color: var(--v-secondary-base);
  }
</style>
